import React, {useState, useEffect, useRef, useMemo} from 'react';
import PropTypes from 'prop-types';
import styles from "./sharedPageStyles.module.scss";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import * as ss from 'simple-statistics';
import {
  capitalise, 
  getCropColour, 
  makeAColour, 
  ColourLuminance, 
  BoxPlotData} from "../functions";


//region Structure of BoxPlotData property:
//  {
//    AllSeasons: {
//      reference: {
//        crop1: {},
//        crop2: {},
//        foundCrops: ['crop1', 'crop2'],
//        indicators: [{indicator objects}...],
//        maxMins: {
//          indicator1: {max: value, min: value},
//          ...
//        },
//      },
//      alternate: {
//        as per above
//      },
//    SelectSeasons: {
//      same as AllSeasons above
//    }
//  }
//endregion

const groupLabels = ['reference', 'alternative', ' ', 'reference', 'alternative'];

const BoxChart2 = (props) => {
  const effectDone = useRef(false);
  const [boxPlots, setBoxPlots] = useState(null);

  useEffect(() => {
    // console.log('BoxChart2 props', props);
  }, []);

  const combinedCropsArray = useMemo(() => {
    let cropArray = [];
    if (props.BoxPlotData) {
      const cropSet = new Set();
      if (props.BoxPlotData.AllSeasons?.alternative) {
        props.BoxPlotData.AllSeasons.alternative.foundCrops.forEach(c => {
          cropSet.add(c);
        })
      }
      if (props.BoxPlotData.AllSeasons?.reference) {
        props.BoxPlotData.AllSeasons.reference.foundCrops.forEach(c => {
          cropSet.add(c);
        })
      }
      cropArray = Array.from(cropSet);
    }

    return cropArray;

  }, [props.BoxPlotData]);

  const cropSeries = useMemo(() => {
    let boxSeries = [];

    const emptySeries = () => {
      const series = {
        name: null,
        id: null,
        data: [],
        color: null,
        lineColor: '#000000',
        medianColor: '#000000',
        stemColor: '#000000',
        stemDashStyle: "dash",
        whiskerColor: null,
        fillColor: null,
      };

      return series;
    };

    const makeBox = (xIndex, cropDataObj, resultIndicator) => {
      const box = {
        x: xIndex,
        low: 0,
        q1: 0,
        median: 0,
        q3: 0,
        high: 0,
      };

      if (cropDataObj) {
        if (cropDataObj.hasOwnProperty(resultIndicator)) {
          const indicatorData = cropDataObj[resultIndicator];

          // console.log(indicatorData);
          if (indicatorData.data?.length > 0) {
            let boxPlotData = BoxPlotData(indicatorData.data);
            // console.log(cropDataObj);
            // console.log(boxPlotData);
            box.low = boxPlotData.min;
            box.high = boxPlotData.max;
            box.median = boxPlotData.median;
            box.q1 = boxPlotData.q2;
            box.q3 = boxPlotData.q4;
          }
        }
      }
      return box;
    };

    if (combinedCropsArray.length > 0) {
      // console.log(combinedCropsArray);
      for(let i = 0; i < combinedCropsArray.length; i++) {
        const cropName = combinedCropsArray[i];
        const cropColour = getCropColour(props.Crops, cropName, true);
        const cropSolidColour = getCropColour(props.Crops, cropName, false);

        const series = {
          name: cropName,
          id: cropName,
          data: [],
          color: cropColour,
          lineColor: '#000000',
          medianColor: '#000000',
          stemColor: '#000000',
          stemDashStyle: "dash",
          whiskerColor: cropSolidColour,
          fillColor: cropColour,
        };

        // console.log('BoxPlotData', props.BoxPlotData);
        if (props.BoxPlotData.AllSeasons.reference.hasOwnProperty(cropName)) {
          const cropData = props.BoxPlotData.AllSeasons.reference[cropName];
          series.data.push(makeBox(0, cropData, props.ResultIndicator))
        }
        if (props.BoxPlotData.AllSeasons.alternative.hasOwnProperty(cropName)) {
          const cropData = props.BoxPlotData.AllSeasons.alternative[cropName];
          series.data.push(makeBox(1, cropData, props.ResultIndicator))
        }

        if (props.BoxPlotData.SelectSeasons.reference.hasOwnProperty(cropName)) {
          const cropData = props.BoxPlotData.SelectSeasons.reference[cropName];
          series.data.push(makeBox(3, cropData, props.ResultIndicator))
        }
        if (props.BoxPlotData.SelectSeasons.alternative.hasOwnProperty(cropName)) {
          const cropData = props.BoxPlotData.SelectSeasons.alternative[cropName];
          series.data.push(makeBox(4, cropData, props.ResultIndicator))
        }

        boxSeries.push(series);
      }
    }

    // console.log('BoXSeries', boxSeries);
    return boxSeries;
  }, [props.BoxPlotData, props.ResultIndicator, props.SeasonalConditions]);

  const getTitle = () => {
    if (props.Title === '') {
      return 'Boxplots: ' + capitalise(props.ResultIndicatorDesc);
    } else {
      return props.Title;
    }
  };

  const getYAxisTitle = () => {
    let result = '';
    if (props.YAxisTitle === '') {
      result = props.ResultIndicatorDesc;
      if (props.Units !== '') {
        result += ` (${props.Units})`;
      }
    } else {
      result = props.YAxisTitle;
    }

    return result;
  };

  const getChartOptions = () => {
    return {
      chart: {
        type: 'boxplot',
        events: {
          render: function() {
            const chart = this;

            const chartWidth = chart.chartWidth;
            const allIndex = 0;
            const seasonalIndex = Math.ceil(chart.xAxis[0].categories.length / 2);
            const all_x = chart.xAxis[0].toPixels(allIndex, true) + 50;
            // const seasonal_x = chart.xAxis[0].toPixels(seasonalIndex, true) + 50;
            // This is very 'hacky' but haven't figured out how to do it properly.
            const centerOffset = Math.ceil(chartWidth * .10) + 10;
            // const centerOffset = 85;
            const seasonal_x = Math.ceil(chartWidth / 2) + centerOffset;
            const position_y = 42;

            if (chart.textAll) {
              chart.textAll.destroy();
              chart.textAll = undefined;
            }

            if (chart.textSeasonal) {
              chart.textSeasonal.destroy();
              chart.textSeasonal = undefined;
            }

            chart.textAll = chart.renderer.text('All Seasons', all_x, position_y)
              .css({
                color: '#4572A7',
                fontSize: '30px',
                lineHeight: '30%'
              })
              .add();

            chart.textSeasonal = chart.renderer.text('Selected Seasons', seasonal_x, position_y)
              .css({
                color: '#4572A7',
                fontSize: '30px',
                lineHeight: '30%'
              })
              .add();
          }
        }
      },
      title: {
        text: getTitle(),
        useHTML: true,
      },
      legend: {
        enabled: true,
      },
      credits: {
        enabled: false,
      },

      xAxis: [{
        categories: groupLabels,
        // enabled : true,
        // labels: {
        //   style: {
        //     fontSize: '1.2em',
        //   }
        // }
      }],
      yAxis: [{
        title: {
          text: getYAxisTitle(),
        },
        min: 0,
      }],
      tooltip: {
        formatter: function () {
          //const colourTitle = this.color; some colours are too light to be readable.
          const colourTitle = '#000000';  // use black instead
          const boxTitle = this.x + ' - ' + this.series.name;

          return   '<span style="color:' + colourTitle + ';font-weight:bold;">' + boxTitle + '</span><br/>' +
            '95% (Top Whisker) : ' + this.point.high + ' ' + props.Units + '<br/>' +
            '75% (Top Box): ' + this.point.q3 + ' ' + props.Units + '<br/>' +
            '50% (Median - Black Line): ' + this.point.median + ' ' + props.Units + '<br/>' +
            '25% (Bottom Box): ' + this.point.q1 + ' ' + props.Units + '<br/>' +
            '5% (Bottom Whisker): ' + this.point.low + ' ' + props.Units + '<br/>';
        }
      },
      plotOptions: {
        series: {
          grouping: true,
          groupPadding: 0.2,
          pointPadding: 0.1,
        }
      },
      series: [...cropSeries],
    }
  };

  return (
    <div className={styles.graphContainer}>
      {props.BoxPlotData !== null && <div>
        <HighchartsReact
          highcharts={Highcharts}
          options={getChartOptions()}
          constructorType={ 'chart' }
        />
      </div>}
      <div className={styles.chartExplanation}>
        {props.Explanation}
      </div>
    </div>
  );
}

BoxChart2.propTypes = {
  BoxPlotData: PropTypes.object.isRequired,
  ResultIndicator: PropTypes.string.isRequired,
  ResultIndicatorDesc: PropTypes.string.isRequired,
  SeasonalConditions: PropTypes.array.isRequired,
  Crops: PropTypes.array.isRequired,
  Units: PropTypes.string.isRequired,
  Title: PropTypes.string,
  Explanation: PropTypes.string,
  YAxisTitle: PropTypes.string,
  InfoKey: PropTypes.string,
};

BoxChart2.defaultProps = {
  Title: '',
  Explanation: '',
  YAxisTitle: '',
  InfoKey: '',
};

export default BoxChart2;
