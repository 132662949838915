import React, {useState, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Box, Grid, Typography} from "@mui/material";

import {useSelector} from "react-redux";
import ResultIndicatorModal from "../ra_components/ResultIndicatorModal";
import SeasonalCondModal from "../ra_components/SeasonalCondModal";
import styles from "../ra_components/sharedPageStyles.module.scss";
import InfoButton from "../ra_components/InfoButton";
import BoxChart2 from "../ra_components/BoxChart2";
import {anycaseEquals, getCropColour, SummariseDataByIndicator} from "../functions";
import {selectWaterBalance} from "../features/sitesSlice";
import {selectCrops, selectCropLevelIndicators} from "../features/rotationSlice";
import miscHelper from "../ra_Helpers/miscHelper";
import LineChart2 from "../ra_components/LineChart2";

const seasonalCond = miscHelper.seasonalClimateConditions();
const bandColour = 'rgba(255,20,20,0.1)';

const CompareCropLevel = (props) => {
  const [refLineChartTitle, setRefLineChartTitle] = useState('');
  const [altLineChartTitle, setAltLineChartTitle] = useState('');
  const [lineChartMin, setLineChartMin] = useState(0);
  const [lineChartMax, setLineChartMax] = useState(100);
  const [refLineChartData, setRefLineChartData] = useState(null);
  const [altLineChartData, setAltLineChartData] = useState(null);
  const [boxPlotData, setBoxPlotData] = useState(null);

  const allIndicators = useSelector(selectCropLevelIndicators);
  const crops = useSelector(selectCrops);
  const waterBalance = useSelector(selectWaterBalance);

  //region Memoised variables
  const selectableIndicators = useMemo(() => {
    return allIndicators.filter(r => r.selectable);
  }, [allIndicators]);

  const currentIndicatorDesc = useMemo(() => {
    if (props.CurrentIndicator) {
      const indicatorObj = allIndicators.find(r => anycaseEquals(r.value, props.CurrentIndicator));
      if (indicatorObj) {
        setRefLineChartTitle(`Annual results (Reference): ${indicatorObj.display}`);
        setAltLineChartTitle(`Annual results (Alternative): ${indicatorObj.display}`);
        return indicatorObj.display;
      } else {
        return '';
      }
    }
  }, [props.CurrentIndicator, allIndicators]);

  const currentIndicatorObj = useMemo(() => {
    if (props.CurrentIndicator) {
      const indicatorObj = allIndicators.find(r => anycaseEquals(r.value, props.CurrentIndicator));
      if (indicatorObj) {
        return indicatorObj;
      } else {
        return null;
      }
    }
  }, [props.CurrentIndicator, allIndicators]);

  const currentUnits = useMemo(() => {
    if (props.CurrentIndicator) {
      const indicatorObj = allIndicators.find(r => anycaseEquals(r.value, props.CurrentIndicator));
      if (indicatorObj) {
        return indicatorObj.units;
      } else {
        return '';
      }
    }
  }, [props.CurrentIndicator, allIndicators]);

  const selectedWaterBalance = useMemo(() => {
    let results = [];
    if (props.CurrentConditions) {
      results = waterBalance.filter(wb => props.CurrentConditions.includes(wb.condition));
    }
    return results;
  }, [props.CurrentConditions, waterBalance]);

  const findCrops = (rotationObj) => {
    if (rotationObj) {
      let cropSet = new Set();
      Object.keys(rotationObj).forEach(key => {
        if (key.includes('Summer') || key.includes('Winter')) {
          if (rotationObj[key] !== 'fallow') {
            cropSet.add(rotationObj[key]);
          }
        }
      });

      return  Array.from(cropSet);
    } else {  
      return null;  
    }  
  };

  const formatLineChartData = (data, indicator, foundCrops) => {
    // Returns an array of cropDataObj objects:
    // {name: crop, color: cropSolidColour, data: [], min: null, max: null}
    let results = [];

    foundCrops.forEach(crop => {
      const cropSolidColour = getCropColour(crops, crop, false);
      const cropDataObj = {
        name: crop,
        color: cropSolidColour,
        data: [], 
        min: null, 
        max: null
      };

      const cropData = data.filter(d => d.crop === crop);
      cropData.sort((a, b) => a.year - b.year);
      cropDataObj.data = cropData.map(c => {
        const value = c[indicator];
        if (cropDataObj.min == null || value < cropDataObj.min) {
          cropDataObj.min = value;
        }
        if (cropDataObj.max == null || value > cropDataObj.max) {
          cropDataObj.max = value;
        }
        const point = {
          x: c.year,
          y: c[indicator]
        };

        return point;
      });

      results.push(cropDataObj);
    });

    return results;
  };

  useEffect(() => {
    if (props.CompareData && currentIndicatorObj) {
      //#region Setup line chart data.
      const indicator = currentIndicatorObj.value;
      const refRotation = props.CompareData.rotations.find(r => r.Type === 'reference');
      if (refRotation) {
        const foundCrops = findCrops(refRotation);
        const refLineData = formatLineChartData(refRotation.cult_seasonal, indicator, foundCrops);
        
        refLineData.push(
          {
            marker: {
              symbol: 'square',
              radius: 20,
            },
            type: 'scatter',
            name: 'Selected Seasons',
            color: bandColour,
          }
        );

        setRefLineChartData(refLineData);
      }

      const altRotation = props.CompareData.rotations.find(r => r.Type === 'alternative');
      if (altRotation) {
        const foundCrops = findCrops(altRotation);
        const altLineData = formatLineChartData(altRotation.cult_seasonal, indicator, foundCrops);
        
        altLineData.push(
          {
            marker: {
              symbol: 'square',
              radius: 20,
            },
            type: 'scatter',
            name: 'Selected Seasons',
            color: bandColour,
          }
        );

        setAltLineChartData(altLineData);
      } 
      //#endregion

      //#region Setup box plot data.
      const boxData = {
        AllSeasons: {
          name: 'All Seasons',
          alternative: null,
          reference: null,
        },
        SelectSeasons: {
          name: 'Selected Seasons',
          alternative: null,
          reference: null,
        },
      };

      props.CompareData.rotations.forEach(rotation => {
        const dataAllSeasons = rotation.cult_seasonal.slice(0);
        const dataSeasonal = rotation.cult_seasonal.filter(d => props.CurrentConditions.includes(d.condition));  
      
        // Summarise the data.
        const summAllSeasons = SummariseDataByIndicator(dataAllSeasons, allIndicators, crops);
        const summSeasonal = SummariseDataByIndicator(dataSeasonal, allIndicators, crops);

        boxData.AllSeasons[rotation.Type] = summAllSeasons;
        boxData.SelectSeasons[rotation.Type] = summSeasonal;
      });

      // console.log('boxData', boxData);
      setBoxPlotData(boxData);
      //#endregion

      // Calculate the line chart min and max.
      calculateLineChartMinMax();

      // Set the line chart titles.
      setRefLineChartTitle(`Results per harvest: (Reference) ${currentIndicatorDesc}`);
      setAltLineChartTitle(`Results per harvest: (Alternative) ${currentIndicatorDesc}`);
    }

  }, [props.CompareData, currentIndicatorObj, crops, allIndicators, props.CurrentConditions]);

   const handleIndicatorChange = (index, new_indicator) => {
    // console.log('CompareCropLevel handleIndicatorChange');
    // In this instance the index can be ignored.
    if (new_indicator) {
      if (new_indicator.length > 0) {
        props.OnIndicatorChange(new_indicator);
      }
    }
  };

  const handleSeasonalConditionsChange = (conditions) => {
    // console.log('got conditions', conditions);
    props.OnConditionsChange(conditions);
  };

  const calculateLineChartMinMax = () => {
    let overallMin = null;
    let overallMax = null;
    let chartMin = null;
    let chartMax = null;
  
    if (refLineChartData) {
      refLineChartData.forEach(d => {
        if (overallMin == null || d.min < overallMin) {
          overallMin = d.min;
        }
        if (overallMax == null || d.max > overallMax) {
          overallMax = d.max;
        }
      });
    }
  
    if (altLineChartData) {
      altLineChartData.forEach(d => {
        if (overallMin == null || d.min < overallMin) {
          overallMin = d.min;
        }
        if (overallMax == null || d.max > overallMax) {
          overallMax = d.max;
        }
      });
    }
  
    if (overallMin && overallMax) {
      chartMin = Math.ceil(Math.abs(overallMin) / 100) * 100;
      if (overallMin < 0) {
        chartMin = chartMin * -1;
      }
      chartMax = Math.ceil(Math.abs(overallMax) / 100) * 100;
      if (overallMax < 0) {
        chartMax = chartMax * -1;
      }
    }

    setLineChartMin(chartMin);
    setLineChartMax(chartMax);
  };

  return (
    <Box >
      <Box sx={{flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
        <Box sx={{flex: 1, display: "flex", flexDirection: "column", justifyContent: "flex-start"}}>
          <div style={{width: '100%'}}>
            <Typography
              sx={{ width: '90%', flexShrink: 0, ml: 10, pb: 1, color: 'black'}}
              fontSize={14} >
                The below figures provide separate results per harvested crop. Please use the 
                blue button to display results for indicators and seasonal climate conditions 
                of your interest.
            </Typography>
            <Box sx={{flex: 1, display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
              <div>
                <ResultIndicatorModal
                  CurrentIndicator={props.CurrentIndicator}
                  CurrentIndicatorDesc={currentIndicatorDesc}
                  SelectableIndicators={selectableIndicators}
                  OnResultIndicatorChange={handleIndicatorChange}
                  Label='Result Indicator:'
                  LabelBefore={false}
                  WithBorder={false} />
              </div>
              <div style={{marginLeft: '10px'}}>
                <SeasonalCondModal
                  OnSeasonalConditionsChange={handleSeasonalConditionsChange}
                  Label="Selected Seasons:"
                  SeasonalCondList={seasonalCond}
                  SelectedConditions={props.CurrentConditions} />
              </div>
            </Box>  
          </div>  
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div id="boxplotcontainer" className={styles.graphPadded}>
              <InfoButton InfoKey="cl_box_plot" Position='auto' AnchorTo="boxplotcontainer">
                {boxPlotData && <BoxChart2
                  BoxPlotData={boxPlotData}
                  ResultIndicator={props.CurrentIndicator}
                  ResultIndicatorDesc={currentIndicatorDesc}
                  SeasonalConditions={props.CurrentConditions}
                  Crops={crops}
                  Units={currentUnits}
                  InfoKey="box_plot"
                />}
              </InfoButton>
            </div>
          </Grid>
          <Grid item xs={6}>
            {refLineChartData && <div id="linechartcontainer1" className={styles.graphPadded}>
              <InfoButton InfoKey="cl_line_chart" Position='auto' AnchorTo="linechartcontainer1">
                <LineChart2
                  SeasonalYears={selectedWaterBalance}
                  Series={refLineChartData}
                  ResultIndicator={props.CurrentIndicator}
                  ResultIndicatorDesc={currentIndicatorDesc}
                  Crops={crops}
                  Units={currentUnits}
                  YAxisMin={lineChartMin}
                  YAxisMax={lineChartMax}
                  Title={refLineChartTitle}
                />
              </InfoButton>
            </div>}
          </Grid>
          <Grid item xs={6}>
            {altLineChartData && <div id="linechartcontainer2" className={styles.graphPadded}>
              <InfoButton InfoKey="cl_line_chart" Position='auto' AnchorTo="linechartcontainer2">
                <LineChart2
                  SeasonalYears={selectedWaterBalance}
                  Series={altLineChartData}
                  Title={altLineChartTitle}
                  ResultIndicator={props.CurrentIndicator}
                  ResultIndicatorDesc={currentIndicatorDesc}
                  Crops={crops}
                  Units={currentUnits}
                  YAxisMin={lineChartMin}
                  YAxisMax={lineChartMax}
                />
              </InfoButton>
            </div>}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

CompareCropLevel.propTypes = {
  CompareData: PropTypes.object.isRequired,
  CurrentIndicator: PropTypes.string.isRequired,
  CurrentConditions: PropTypes.array.isRequired,
  AltRotationId: PropTypes.number.isRequired,
  RefRotationId: PropTypes.number.isRequired,
  OnIndicatorChange: PropTypes.func,
  OnConditionsChange: PropTypes.func,
};

CompareCropLevel.defaultProps = {
  OnIndicatorChange: () => {},
  OnConditionsChange: () => {},
}

export default CompareCropLevel;
