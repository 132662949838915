import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axiosRotation from "../ra_data/axiosRotation";
import {ExploreListRowType, SortExploreTableData} from "../functions";

const initialState = {
  selectedCrops: [],
  rotationLevelIndicator: 'gross_margin',
  cropLevelIndicator: 'yield',
  alternateRotation: null,
  compareStatus: 'idle',
  compareError: null,
  compareData: null,
  seasonalConditions: [],   // User selected seasonal conditions
};

//region Fetch result data from API.

// expected parms = {
//  siteId:
//  pawc:
//  Rotations: [
//    {
//      Type: string,
//      RotationId: number,
//      SowingRuleId: number,
//      NLevel: string,
//    }
//  SeasonCond: [string],
// }

export const fetchCompareData = createAsyncThunk(
  'results/fetchCompareData',
  async ({parms}, {getState}) => {
    try {
      if (parms) {
        const url = `/compare?siteId=${parms.siteId}&pawc=${parms.pawc}`;
        const response = await axiosRotation.post(url, parms);
        return response.data;
      }
    } catch (err) {
      return err.message;
    }
  }
);

//endregion

export const exploreSlice = createSlice({
  name: 'explore',
  initialState,
  reducers: {
    updateSelectedCrops: (state, action) => {
      // Payload is expected to be an array of crop names.
      state.selectedCrops = [...action.payload];
    },
    clearSelectedCrops: (state) => {
      state.selectedCrops = [];
    },
    updateRotationLevelIndicator: (state, action) => {
      // console.log('Explore Update Result Indicator', action.payload);
      const {resultIndicator} = action.payload;

      if (resultIndicator) {
        // Modify the item with the new information.
        state.rotationLevelIndicator = resultIndicator;
      }
    },
    updateCropLevelIndicator: (state, action) => {
      const {resultIndicator} = action.payload;

      if (resultIndicator) {
        // Modify the item with the new information.
        state.cropLevelIndicator = resultIndicator;
      }
    },
    updateAlternateRotation: (state, action) => {
      const {rotation} = action.payload;

      if (rotation) {
        // Modify the item with the new information.
        state.alternateRotation = {Type: 'alternative', ...rotation};
      }
    },
    updateSeasonalConditions: (state, action) => {
      const conditions = action.payload;

      state.seasonalConditions = [...conditions];
    },
    resetAlternateRotation: (state, action) => {
      state.alternateRotation = null;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCompareData.pending, (state, action) => {
        state.compareStatus = 'loading';
      })
      .addCase(fetchCompareData.fulfilled, (state, action) => {
        state.compareStatus = 'success';
        state.compareError = null;
        // console.log('exploreSlice_fetchCompareData - success', action.payload);
        state.compareData = action.payload;
      })
      .addCase(fetchCompareData.rejected, (state, action) => {
        state.compareStatus = 'failed';
        state.compareError = action.error.message;
      })
  },
});

export const {updateSelectedCrops, clearSelectedCrops} = exploreSlice.actions;
export const {updateAlternateRotation} = exploreSlice.actions;
export const {updateCropLevelIndicator, updateRotationLevelIndicator} = exploreSlice.actions;
export const {updateSeasonalConditions, resetAlternateRotation} = exploreSlice.actions;

export const selectSelectedCrops = (state) => state.explore.selectedCrops;

export const selectAlternateRotation = (state) =>  state.explore.alternateRotation;
export const selectSeasonalConditions = (state) =>  state.explore.seasonalConditions;
export const selectExploreRotationLevelIndicator = (state) => state.explore.rotationLevelIndicator;
export const selectExploreCropLevelIndicator = (state) => state.explore.cropLevelIndicator;

// Pulls data from other slices...
export const selectExploreRotations = (state) => {
  const rotations = [];

  const refRotation = {
    Type: 'reference',
    RotationId: state.rotation.baseRotationId,
    NLevel: state.rotation.nitrogenLevel,
    SowingRuleId: state.sowingRules.sowingRule,
  };

  rotations.push(refRotation);
  rotations.push(state.explore.alternateRotation);
  return rotations.slice();
};

export const selectCompareStatus = (state) => state.explore.compareStatus;
export const selectCompareError = (state) => state.explore.compareError;
export const selectCompareData = (state) => state.explore.compareData;

export default exploreSlice.reducer;
