import React from 'react';
import PropTypes from 'prop-types';
import styles from './NavSection.module.scss';
import Container from '@mui/material/Container';
import NavBar from './NavBar';

const NavSection = (props) => {

    return (
        <div className={styles.navContainer}>
            <Container maxWidth="lg">
                <NavBar navItems={props.navItems}></NavBar>
            </Container>
        </div>
    );

};

NavSection.propTypes = {
    navItems: PropTypes.arrayOf(PropTypes.object)
};

export default NavSection;
