import React, {useState, useEffect, useRef, useMemo} from 'react';
import PropTypes from 'prop-types';
import { useLocation } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Typography from "@mui/material/Typography";
import Tooltip from '@mui/material/Tooltip';
import styles from './InterpretationStep.module.scss';
import pageStyles from "./Results.module.scss";
import CompareRotationList from "../ra_components/CompareRotationList";
import {resetAlternateRotation, selectAlternateRotation, selectCompareData} from "../features/exploreSlice";
import {
  getReferenceRotation,
  selectCrops,
  replaceRotation
} from "../features/rotationSlice";


const tooltip2 = ('The current alternative cropping strategy will become ' +
  'the reference cropping strategy. You then select a new ' +
  'alternative cropping strategy.');
const tooltip1 = ('The reference cropping strategy remains unchanged. ' +
  'You will select a new alternative cropping strategy.');


const InterpretationStep = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const referenceRotation = useSelector(getReferenceRotation);
  const alternateRotation = useSelector(selectAlternateRotation);
  const compareData = useSelector(selectCompareData);
  const crops = useSelector(selectCrops);

  console.log('Alternate Rotation', alternateRotation);

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_path: location.pathname + location.search + location.hash,
      page_search: location.search,
      page_hash: location.hash
    });
  }, [location]);

  const handleNewReferenceStrategy = () => {
    props.RedirectRefSwap();
  };

  const handleNewAlternateStrategy = () => {
    props.RedirectNewAlt();
  };

  return (
    <div style={{padding: 2}}>
      <div className={styles.table_area}>
        {compareData && <CompareRotationList
          Rotations={compareData.rotations}
          Crops={crops} />
        }
      </div>
      <div className={styles.text_area}>
        <Typography
          sx={{ width: '33%', flexShrink: 0, ml: 4, color: 'black'}}
          fontWeight="bold"
          fontSize={20} >
          Step 1: Results interpretation
        </Typography>
        <Typography
          sx={{flexShrink: 0, ml: 4, color: 'black'}}
          fontSize={20} >
          You reached the end of the comparison of your reference and alternative cropping strategy. When
          considering the specific context conditions of you own enterprise. What are the strengths and
          weaknesses of these two strategies? What opportunities do they open up and what risks do they
          carry?
        </Typography>
        <Typography
          sx={{ flexShrink: 0, ml: 4, color: 'black'}}
          fontSize={20} >
          The results from this tool may help you to conduct your own reflections if there is any value in
          changing the current cropping strategy of your farm enterprise. It is important to note that this
          tool should not be used as the sole basis of such major on-farm decisions. Instead, it is essential
          to further consider the implications from investments in machinery, the timing of labour
          availability, and a range of further specific circumstances of your farm - for exampler as part
          of detailed discussions with an agronomist.
        </Typography>
      </div>
      <div className={styles.text_area}>
        <Typography
          sx={{ width: '33%', flexShrink: 0, ml: 4, color: 'black'}}
          fontWeight="bold"
          fontSize={20} >
          Step 2: Explore further cropping strategies
        </Typography>
        <Typography
          sx={{ flexShrink: 0, ml: 4, color: 'black'}}
          fontSize={20} >
          As part of your comparison, you may have identfied remaining weaknesses or underutilised
          opportunities of both cropping strategies. You may also want to explore still other options
          that you consider promising. In that case, please use one of the buttons below. They will
          set one of the current cropping strategies to become the new reference cropping strategy,
          which you can then compare to a further alternative option. Typically, tool-users are
          advised to repeat this iterative step several times to explore a number of different
          cropping strategies.
        </Typography>
      </div>

      <div className={styles.button_area}>
        <Tooltip title={tooltip1} >
          <div 
            className={styles.div_button} 
            onClick={handleNewAlternateStrategy} 
            style={{cursor: 'pointer', textAlign: 'center'}}>
            <Typography
              sx={{flexShrink: 0, ml: 4, mb: 4, color: 'black', textDecoration: 'underline'}}
              fontSize={20} >
              Explore further options
            </Typography>
            <Typography
              sx={{flexShrink: 0, ml: 4, color: 'black'}}
              fontSize={20} >
              Continue with current reference
            </Typography>
          </div>
        </Tooltip>
        <Tooltip title={tooltip2} >
          <div 
            className={styles.div_button} 
            onClick={handleNewReferenceStrategy} 
            style={{cursor: 'pointer', textAlign: 'center'}}> 
            <Typography
              sx={{flexShrink: 0, ml: 4, mb: 4, color: 'black', textDecoration: 'underline'}}
              fontSize={20} >
              Explore further options
            </Typography>
            <Typography
              sx={{ flexShrink: 0, ml: 4, color: 'black'}}
              fontSize={20} >
              Continue with current alternative
            </Typography>
          </div>
        </Tooltip>
      </div>
    </div>
  );
}

InterpretationStep.propTypes = {
  RedirectRefSwap: PropTypes.func,
  RedirectNewAlt: PropTypes.func,
}

InterpretationStep.defaultProps = {
  RedirectRefSwap: () => {},
  RedirectNewAlt: () => {},
}

export default InterpretationStep;
