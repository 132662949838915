import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import * as ss from 'simple-statistics';
import {capitalise, makeAColour, ColourLuminance, BoxPlotData} from "../functions";

import styles from './sharedPageStyles.module.scss';
import chartStyles from './Charts.module.scss';


HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
highchartsAccessibility(Highcharts);

const BoxChart = (props) => {
  const effectDone = useRef(false);
  const [chartData, setChartData] = useState([]);
  const [cropLabels, setCropLabels] = useState([]);
  const [seasonMatches, setSeasonMatches] = useState([]);

  useEffect(() => {
    if (effectDone.current === true) {
      getChartData();
    }

    return () => {
      effectDone.current = true;
    }
  }, [props.ResultsData, props.ResultIndicator, props.SeasonalConditions]);

  const getCropColour = (cropname, withGradient = true) => {
    let result = null;

    const lcCropName = cropname.toString().trim().toLowerCase();
    const aCrop = props.Crops.find(c => c.Name.toLowerCase() === lcCropName);

    if (aCrop) {
      const startColour = makeAColour(aCrop.bg_colour);

      if (withGradient) {
        let stopColour = ColourLuminance(startColour, 0.2);
        if (aCrop.neverDarken === 1) {
          stopColour = ColourLuminance(startColour, 1.2);
        }
        const perShapeGradient = {x1: 0, y1: 0, x2: 1, y2: 1};
        const gradient = {
          linearGradient: perShapeGradient,
          stops: [[0, startColour], [1, stopColour]],
        };

        result = gradient;
      } else {
        result = makeAColour(aCrop.bg_colour);
      }
    }

    return result;
  };

  const buildABox = (dataObj, index, cropName) => {

    const cropColour = getCropColour(cropName, true);
    const cropSolidColour = getCropColour(cropName, false);

    if (dataObj["q1"] !== null) {
      // console.log(dataObj);
      // Values have already been rounded.
      return {
        x: index,
        low: dataObj.low,
        q1: dataObj.q1,
        median: dataObj.median,
        q3: dataObj.q3,
        high: dataObj.high,
        name: cropName,
        fillColor: cropColour,
        mean: dataObj["mean"],
        whiskerColor: cropSolidColour,
        whiskerLength: '20%',
        whiskerWidth: 4,
        medianColor: 'black',
        stemColor: 'black',
        stemDashStyle: 'dot',
        stemWidth: 2,
        units: props.Units,
      };
    } else {
      const cropOutlier = {
        x: index,
        name: `outlier${index}`,
        color: cropSolidColour,
        type: 'scatter',
        data: [],
        marker: {
          fillColor: cropSolidColour,
          lineWidth: 1,
          lineColor: 'black',
        },
        tooltip: {
          pointFormat: `Crop ${capitalise(cropName)}: {point.y}`
        }

      };

      dataObj.data.forEach(d => {
        cropOutlier.data.push([index, d.value]);
      });

      return cropOutlier;
    }

    return {};
  };

  const getChartData = () => {
    const xData = [];
    const xLabels = [];

    const foundCropsSet = new Set();
    let cropData = {};
    const seasonalMatches = [];

    if (props.ResultsData) {
      //region Group the data by crop
      props.ResultsData.forEach(data => {

        const cropName = data.crop;

        if (cropName !== 'fallow') {
          foundCropsSet.add(cropName);

          //region Make sure cropData object has the crop property
          if (!cropData.hasOwnProperty(cropName)) {
            cropData[cropName] = {
              allCond: {data: [], name: cropName, mean: null, median: null, high: null, low: null, q1: null, q3: null},
              seasCond: {data: [], name: cropName, mean: null, median: null, high: null, low: null, q1: null, q3: null},
            };
          }
          //endregion

          // Create a data point object. Use the current Result Indicator
          // property to grab a value from the correct column in the data.
          const point = {
            year: data.year,
            season: data.season,
            value: data[props.ResultIndicator],
          };

          // Check for matching seasonal conditions.
          if (props.SeasonalConditions.includes(data.condition)) {
            // Record this in seasonalMatches.
            const match = {
              year: data.year,
              season: data.season,
              condition: data.condition
            };
            seasonalMatches.push(match);

            cropData[cropName].seasCond.data.push(point);
          }

          // Include everything in all conditions data.
          cropData[cropName].allCond.data.push(point);
        }
      });
      //endregion

      setSeasonMatches([...seasonalMatches]);

      //region Calculate values and plot them.
      const foundCrops = Array.from(foundCropsSet);
      foundCrops.sort();

      let boxIndex1 = 0;    // First group of box plots (all conditions)
      foundCrops.forEach(c => {
        const boxIndex2 = boxIndex1 + foundCrops.length + 1;  // Second group of box plots (seasonal conditions)

        const allValues = cropData[c].allCond.data.map(a => {return Math.round(a.value * 1000) / 1000;});
        allValues.sort();
        const calcMean = allValues.reduce((a, b) => a + b, 0) / allValues.length;

        const seasValues = cropData[c].seasCond.data.map(a => {return Math.round(a.value * 1000) / 1000;});
        seasValues.sort();
        const seasCalcMean = seasValues.reduce((a, b) => a + b, 0) / seasValues.length;

        // Align with CropARM percentiles:
        //   Now 5%, 25%, 50%, 75%, 95%
        // if (props.ResultIndicator !== 'crop_failure') {

        if (allValues.length > 2) {
          const allData = BoxPlotData(allValues, 3);
          if (allData.q1) {
            cropData[c].allCond.median = allData.median;
            cropData[c].allCond.mean = allData.mean;
            cropData[c].allCond.low = allData.min;
            cropData[c].allCond.high = allData.max;
            // Box plots seem to use q1 and q3 for box bottom & top.
            cropData[c].allCond.q1 = allData.q2;
            cropData[c].allCond.q3 = allData.q4;
            // cropData[c].allCond.q4 = allData.q4;
            // cropData[c].allCond.q2 = allData.q2;
            // cropData[c].allCond.q5 = allData.q5;
          }
        } else {
          cropData[c].allCond.median = Math.round(calcMean * 1000) / 1000;
          cropData[c].allCond.q1 = calcMean;
          // cropData[c].allCond.q2 = calcMean;
          cropData[c].allCond.q3 = calcMean;
          // cropData[c].allCond.q4 = calcMean;
          // cropData[c].allCond.q5 = calcMean;
        }

        if (seasValues.length > 2) {
          const seaData = BoxPlotData(seasValues, 3);
          cropData[c].seasCond.median = seaData.median;
          cropData[c].seasCond.mean = seaData.mean;
          cropData[c].seasCond.low = seaData.min;
          cropData[c].seasCond.high = seaData.max;
          cropData[c].seasCond.q1 = seaData.q2;
          cropData[c].seasCond.q3 = seaData.q4;
          // cropData[c].seasCond.q1 = seaData.q1;
          // cropData[c].seasCond.q2 = seaData.q2;
          // cropData[c].seasCond.q3 = seaData.q3;
          // cropData[c].seasCond.q4 = seaData.q4;
          // cropData[c].seasCond.q5 = seaData.q5;
        } else {
          cropData[c].seasCond.median = Math.round(seasCalcMean * 1000) / 1000;
          cropData[c].seasCond.q1 = seasCalcMean;
          // cropData[c].seasCond.q2 = seasCalcMean;
          cropData[c].seasCond.q3 = seasCalcMean;
          // cropData[c].seasCond.q4 = seasCalcMean;
          // cropData[c].seasCond.q5 = seasCalcMean;
        }
        // }

        // Add crop names to the labels array.
        xLabels.push({x: boxIndex1, label: c});
        xLabels.push({x: boxIndex2, label: c});

        // Create the box plots.
        xData.push(buildABox(cropData[c].allCond, boxIndex1, c));
        xData.push(buildABox(cropData[c].seasCond, boxIndex2, c));

        boxIndex1 += 1;
      });

      // Create the dummy blank boxplot between the groups.
      xData.push({x: foundCrops.length, });
      xLabels.push({x: foundCrops.length, label: ''});

      // Sort the array of boxplot/outliers
      xData.sort((a, b) => {return a.x - b.x;});
      setChartData([...xData]);

      xLabels.sort((a, b) => {return a.x - b.x;});
      setCropLabels(xLabels.map(m => {return capitalise(m.label);}));
      //endregion
    }
  };

  const getTitle = () => {
    if (props.Title === '') {
      return 'Boxplots: ' + capitalise(props.ResultIndicatorDesc);
    } else {
      return props.Title;
    }
  };

  const format3 = (value) => {
    return  (Math.round(value * 1000) / 1000).toFixed(3);
  };

  const getYAxisTitle = () => {
    let result = '';
    if (props.YAxisTitle === '') {
      // result = capitalise(props.ResultIndicator);
      result = props.ResultIndicatorDesc;
      if (props.units !== '') {
        result += ` (${props.Units})`;
      }
    } else {
      result = props.YAxisTitle;
    }

    return result;
  };

  const getChartOptions = () => {
    return {
      chart: {
        type: 'boxplot',
        events: {
          render: function() {
            const chart = this;

            const chartWidth = chart.chartWidth;
            const allIndex = 0;
            const seasonalIndex = Math.ceil(chart.xAxis[0].categories.length / 2);
            const all_x = chart.xAxis[0].toPixels(allIndex, true) + 50;
            // const seasonal_x = chart.xAxis[0].toPixels(seasonalIndex, true) + 50;
            // This is very 'hacky' but haven't figured out how to do it properly.
            const centerOffset = Math.ceil(chartWidth * .10) + 10;
            // const centerOffset = 85;
            const seasonal_x = Math.ceil(chartWidth / 2) + centerOffset;
            const position_y = 42;

            if (chart.textAll) {
              chart.textAll.destroy();
              chart.textAll = undefined;
            }

            if (chart.textSeasonal) {
              chart.textSeasonal.destroy();
              chart.textSeasonal = undefined;
            }

            chart.textAll = chart.renderer.text('All Seasons', all_x, position_y)
              .css({
                color: '#4572A7',
                fontSize: '30px',
                lineHeight: '30%'
              })
              .add();

            chart.textSeasonal = chart.renderer.text('Selected Seasons', seasonal_x, position_y)
              .css({
                color: '#4572A7',
                fontSize: '30px',
                lineHeight: '30%'
              })
              .add();
          }
        }
      },
      title: {
        text: getTitle()
      },
      legend: {
        enabled: false
      },
      xAxis: {
        categories: cropLabels,
        enabled : true,
        labels: {
          style: {
            fontSize: '1.2em',
          }
        }
      },
      yAxis: {
        title: {
          text: getYAxisTitle(),
        }
      },
      tooltip: {
        formatter: function () {
          return   '<span style="color:' + this.color + ';font-weight:bold;">' + this.x + '</span><br/>' +
            '90% (Top Whisker) : ' + this.point.high + ' ' + this.point.units + '<br/>' +
            '75% (Top Box): ' + this.point.q3 + ' ' + this.point.units + '<br/>' +
            '50% (Median - Black Line): ' + this.point.median + ' ' + this.point.units + '<br/>' +
            '25% (Bottom Box): ' + this.point.q1 + ' ' + this.point.units + '<br/>' +
            '10% (Bottom Whisker): ' + this.point.low + ' ' + this.point.units + '<br/>';
        }
      },
      series: [{
        data: chartData,
      }],
    }
  };

  return (
    <div className={styles.graphContainer}>
      <div>
          <HighchartsReact
            highcharts={Highcharts}
            options={getChartOptions()}
            constructorType={ 'chart' }
          />
      </div>
      <div className={styles.chartExplanation}>
        {props.Explanation}
      </div>
    </div>
  );
}

BoxChart.propTypes = {
  ResultsData: PropTypes.array.isRequired,
  ResultIndicator: PropTypes.string.isRequired,
  ResultIndicatorDesc: PropTypes.string.isRequired,
  SeasonalConditions: PropTypes.array.isRequired,
  Crops: PropTypes.array.isRequired,
  Units: PropTypes.string.isRequired,
  Title: PropTypes.string,
  Explanation: PropTypes.string,
  YAxisTitle: PropTypes.string,
  InfoKey: PropTypes.string,
};

BoxChart.defaultProps = {
  Title: '',
  Explanation: '',
  YAxisTitle: '',
  InfoKey: '',
};

export default BoxChart;
