import React, {useState, useEffect, useRef, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import {Box, Grid, Typography} from "@mui/material";
import miscHelper from "../ra_Helpers/miscHelper";
import ResultIndicatorModal from "../ra_components/ResultIndicatorModal";
import SeasonalCondModal from "../ra_components/SeasonalCondModal";
import {
  selectCrops,
  selectSectionCIndicators,
  selectSeasonalConditions as previousSeasonalConditions, selectResultIndicator, selectResultIndicatorObj
} from "../features/rotationSlice";
import {selectWaterBalance} from "../features/sitesSlice";
import {anycaseEquals, CDFValues, makeAColour} from "../functions";
import styles from "../ra_components/sharedPageStyles.module.scss";
import InfoButton from "../ra_components/InfoButton";
import LineChart2 from "../ra_components/LineChart2";
import CDFChart2 from "../ra_components/CDFChart2";
import ParallelChart from "../ra_components/ParallelChart";

const seasonalCond = miscHelper.seasonalClimateConditions();
const allRotationLevelIndicators = miscHelper.compareRotationLevelIndicators();
const bandColour = 'rgba(255,20,20,0.1)';

const CompareRotationLevel = (props) => {
  const effectDone = useRef(false);
  const dispatch = useDispatch();
  const [lineChartTitle, setLineChartTitle] = useState('');
  const [parallelSummaryData, setParallelSummaryData] = useState([]);
  const [cdfChartData, setCDFChartData] = useState([]);
  const [seriesData, setSeriesData] = useState([]);

  const allIndicators = useSelector(selectSectionCIndicators);
  const crops = useSelector(selectCrops);
  const waterBalance = useSelector(selectWaterBalance);

  const selectableIndicators = useMemo(() => {
    return allIndicators.filter(r => r.selectable);
  }, [allIndicators]);

  const currentIndicatorDesc = useMemo(() => {
    // console.log('CompareRotationLevel memo currentIndicatorDesc');
    if (props.CurrentIndicator) {
      const indicatorObj = allRotationLevelIndicators.find(r => anycaseEquals(r.value, props.CurrentIndicator));
      if (indicatorObj) {
        setLineChartTitle(`Annual results: ${indicatorObj.display}`);
        return indicatorObj.display;
      } else {
        return '';
      }
    }

  }, [props.CurrentIndicator]);

  const currentIndicatorObj = useMemo(() => {
    if (props.CurrentIndicator) {
      // console.log('CompareRotationLevel memo currentIndicatorObj');
      const indicatorObj = allRotationLevelIndicators.find(r => anycaseEquals(r.value, props.CurrentIndicator));
      if (indicatorObj) {
        return indicatorObj;
      } else {
        return null;
      }
    }
  }, [props.CurrentIndicator]);

  const currentUnits = useMemo(() => {
    // console.log('CompareRotationLevel memo currentUnits');
    if (props.CurrentIndicator) {
      const indicatorObj = allRotationLevelIndicators.find(r => anycaseEquals(r.value, props.CurrentIndicator));
      if (indicatorObj) {
        return indicatorObj.units;
      } else {
        return '';
      }
    }
  }, [props.CurrentIndicator]);

  const parallelIndicators = useMemo(() => {
    // console.log('CompareRotationLevel memo parallelIndicators');
    const filteredIndicators = allIndicators.filter(r => r.spider);
    filteredIndicators.sort((a, b) => a.spiderSort - b.spiderSort);
    return filteredIndicators;
  }, [allIndicators]);

  const selectedWaterBalance = useMemo(() => {
    let results = [];
    if (props.CurrentConditions) {
      results = waterBalance.filter(wb => props.CurrentConditions.includes(wb.condition));
    }
    return results;
  }, [props.CurrentConditions, waterBalance]);

  //region Prepare data for charts.
  useEffect(() => {
    // console.log('CompareRotationLevel useEffect');
    if (props.CompareData) {
      // setSeriesData([...formatLineChartData()]);
      // setCDFChartData([...formatCDFChartData()]);
      // const resultsData = formatParallelData();
      // setParallelSummaryData(resultsData);
    }
  }, [props.CompareData, currentIndicatorObj]);

  const formattedLineChartData = useMemo(() => {
    // Using props.CompareData.treat_seasonal
    const results = [];

    if (currentIndicatorObj) {
      const indicatorFieldName = currentIndicatorObj.value;

      props.CompareData.rotations.forEach(rotation => {
        // Create a series for each rotation.
        let seriesColour = miscHelper.getCompareColour(miscHelper.CompareColours.ALTERNATE);
        if (rotation.Type === 'reference') {
          seriesColour = miscHelper.getCompareColour(miscHelper.CompareColours.REFERENCE)
        }

        const rotationId = rotation.RotationId;
        const colourThisSeries = makeAColour(seriesColour.colour);

        const rotationSeries = {
          title: `${rotation.Type}`,
          rotationId: rotationId,
          rotation: rotation.Type,
          color: colourThisSeries,
          name: `${rotation.Type}`,
          data: [],
        }

        rotation.treat_seasonal.forEach(yv => {
          const myValue = yv[indicatorFieldName];
          // const myValue = yv[props.CurrentIndicator];
          if (myValue !== null) {
            const data_point = {
              x: yv.year,
              y: myValue,
            }
            rotationSeries.data.push(data_point);
          }
        });

        results.push(rotationSeries);
      });
    }


    results.push(
      {
        marker: {
          symbol: 'square',
          radius: 20,
        },
        type: 'scatter',
        name: 'Selected Seasons',
        color: bandColour,
      }
    );  

    return results;
  }, [props.CompareData, currentIndicatorObj]);

  const formattedCDFChartData = useMemo(() => {
    const results = [];

    if (currentIndicatorObj) {
      const indicatorFieldName = currentIndicatorObj.value;

      props.CompareData.rotations.forEach(rotation => {
        // Create a series for each rotation.
        let seriesColour = miscHelper.getCompareColour(miscHelper.CompareColours.ALTERNATE);
        if (rotation.Type === 'reference') {
          seriesColour = miscHelper.getCompareColour(miscHelper.CompareColours.REFERENCE)
        }

        const rotationId = rotation.RotationId;
        const colourThisSeries = makeAColour(seriesColour.colour);

        const resultEntry = {
          color: colourThisSeries,
          title: `${rotation.Type}`,
          rotationId: rotationId,
          rotation: rotation.RotationName,
          name: `${rotation.Type}`,
          id: `${rotation.Type}`,
          marker: {enabled: false},
          data: [],
        }

        const field_values = rotation.treat_seasonal.map(i => i[indicatorFieldName]);
        // const field_values = data_values.map(i => i[props.CurrentIndicator]);
        const cdf_values = CDFValues(field_values, false);
        resultEntry.data = cdf_values.slice(0);

        results.push(resultEntry);
      });
    }

    return results;
  }, [props.CompareData, currentIndicatorObj]);

  const formattedParallelData = useMemo(() => {
    const resultsSummary = {
      indicators: [...parallelIndicators],
      maxMins: {},
      series: [],
    };

    // Get the max/mins from the data.
    parallelIndicators.forEach(ind => {
      const minPropName = `${ind.value}_min`;
      const maxPropName = `${ind.value}_max`;

      const maxMinObj = {
        min: props.CompareData["minmax"][0][minPropName],
        max: props.CompareData["minmax"][0][maxPropName],
      };

      resultsSummary.maxMins[ind.value] = {...maxMinObj};
    });

    props.CompareData.rotations.forEach((rotation, index) => {

      let seriesColour = miscHelper.getCompareColour(miscHelper.CompareColours.ALTERNATE1);
      if (rotation.Type === 'reference') {
        seriesColour = miscHelper.getCompareColour(miscHelper.CompareColours.REFERENCE)
      }

      const seriesObj = {
        color: seriesColour.colour,
        name: rotation["Type"],
        data: [],
        shadow: false,
      };

      parallelIndicators.forEach(ind => {
        const indicator = ind.value;

        seriesObj.data.push(rotation.treat_timeAgg[indicator]);
      });

      resultsSummary.series.push(seriesObj);
    });

    // console.log('COmpareRotationLevel resultsSummary', resultsSummary);
    return resultsSummary;
  }, [props.CompareData, parallelIndicators]);
  //endregion

  const handleResultIndicatorChange = (index, new_indicator) => {
    // console.log('CompareRotationLevel handleIndicatorChange', index, new_indicator);
    props.OnResultIndicatorChange(new_indicator);
  };

  const handleSeasonalConditionsChange = (conditions) => {
    // console.log('CompareRotationLevel handleConditionsChange', conditions);
    props.OnSelectedConditionsChange(conditions);
  };

  // Update keys to force chart rerender.
  const linechartKey = Date.now();
  const CDFchartKey = Date.now();

  return (
    <Box >
      <Box sx={{flex: 1, display: "flex", flexDirection: "column", justifyContent: "flex-start"}}>
        <div>
          <Typography
            sx={{ width: '90%', flexShrink: 0, ml: 10, pb: 1, color: 'black'}}
            fontSize={14} >
              The below figure summarises the long-term performance of cropping systems 
              across multiple criteria. The blue performance indicator button allows you 
              to display the best (green line) and worst (red line) performing cropping 
              systems for the chosen indicator and compare it to the performance of the 
              cropping system(s) you specified.
          </Typography>
        </div>
        <Box sx={{flex: 1, display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
          <div>
            <ResultIndicatorModal
                CurrentIndicator={props.CurrentIndicator}
                CurrentIndicatorDesc={currentIndicatorDesc }
                SelectableIndicators={allRotationLevelIndicators}
                OnResultIndicatorChange={handleResultIndicatorChange}
                Label='Result Indicator:'
                LabelBefore={false}
                WithBorder={false}
              />
          </div>
          <div style={{marginLeft: '10px'}}>
            <SeasonalCondModal
              SelectedConditions={props.CurrentConditions}
              OnSeasonalConditionsChange={handleSeasonalConditionsChange}
              Label="Selected Seasons:"/>
          </div>
        </Box>
    </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div id="parchartcontainer" className={styles.graphPadded}>
              <InfoButton InfoKey="rl_par_axis" Position='auto' AnchorTo="parchartcontainer">
                <ParallelChart
                  SummaryData={formattedParallelData}
                  Crops={crops}
                  Explanation=''
                  InfoKey=''
                  TopScale={true}
                />
              </InfoButton>
            </div>
          </Grid>

          <Grid item xs={7}>
            <div id="linechartcontainer" className={styles.graphPadded}>
              <InfoButton InfoKey="cl_line_chart" Position='auto' AnchorTo="linechartcontainer">
                <LineChart2
                  SeasonalYears={selectedWaterBalance}
                  Series={formattedLineChartData}
                  Title={lineChartTitle}
                  ResultIndicator={props.CurrentIndicator}
                  ResultIndicatorDesc={currentIndicatorDesc}
                  Crops={crops}
                  Units={currentUnits}
                  key={linechartKey}
                />
              </InfoButton>
            </div>
          </Grid>

          <Grid item xs={5}>
            <div id="cdfchartcontainer" className={styles.graphPadded}>
              <InfoButton InfoKey="cl_cdf_chart" Position='auto' AnchorTo="cdfchartcontainer">
                <CDFChart2
                  Series={formattedCDFChartData}
                  ResultIndicator={props.CurrentIndicator}
                  ResultIndicatorDesc={currentIndicatorDesc}
                  SeasonalConditions={props.CurrentConditions}
                  Units={currentUnits}
                  key={CDFchartKey}
                />
              </InfoButton>
            </div>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

CompareRotationLevel.propTypes = {
  CompareData: PropTypes.object.isRequired,
  CurrentIndicator: PropTypes.string.isRequired,
  CurrentConditions: PropTypes.array.isRequired,
  OnResultIndicatorChange: PropTypes.func,
  OnSelectedConditionsChange: PropTypes.func,
};

CompareRotationLevel.defaultProps = {
  OnResultIndicatorChange: () => {},
  OnSelectedConditionsChange: () => {},
};

export default CompareRotationLevel;
