import React, {useState, useEffect, useMemo} from 'react';
import {Box, Grid, Typography, Button} from '@mui/material';
import {useDispatch, useSelector} from "react-redux";
import {
  selectCropLevelData,
  selectCropLevelStatus,
  selectCropLevelError
} from "../features/resultsSlice";
import {
  getRotationError,
  getRotationStatus, selectCrops,
  selectResultIndicator,
  selectResultIndicatorObj,
  selectResultIndicators,
  selectSeasonalConditions,
  updateResultIndicator,
  updateSeasonalConditions} from "../features/rotationSlice";
import ResultIndicatorModal from "../ra_components/ResultIndicatorModal";
import BoxChart from "../ra_components/BoxChart";
import LineChart from '../ra_components/LineChart';
import CDFChart from "../ra_components/CDFChart";
import ParallelChart from "../ra_components/ParallelChart";
import SeasonalCondModal from "../ra_components/SeasonalCondModal";
import LoadingComponent from "../ra_components/LoadingComponent";
import miscHelper from "../ra_Helpers/miscHelper";
import {SummariseDataByIndicator} from "../functions";
import InfoButton from "../ra_components/InfoButton";
import styles from '../ra_components/sharedPageStyles.module.scss';
import PlantingsTable from "../ra_components/PlantingsTable";

const seasonalCond = miscHelper.seasonalClimateConditions();

const ResultsCropLevel = (props) => {
  const dispatch = useDispatch();

  const [yearlyResults, setYearlyResults] = useState([]);
  const resultsData = useSelector(selectCropLevelData);
  const resultsStatus = useSelector(selectCropLevelStatus);
  const resultsError = useSelector(selectCropLevelError);
  const crops = useSelector(selectCrops);

  const resultIndicatorObj = useSelector(selectResultIndicatorObj);
  const allIndicators = useSelector(selectResultIndicators);
  const seasonalConditions = useSelector(selectSeasonalConditions);

  // Data required for ParallelChart Component.
  const [parallelSummaryData, setParallelSummaryData] = useState({});

  const selectableIndicators = useMemo(() => {
    return allIndicators.filter(r => r.selectable);
  }, [allIndicators]);

  const parallelIndicators = useMemo(() => {
    const filteredIndicators = allIndicators.filter(r => r.spider);
    filteredIndicators.sort((a, b) => a.spiderSort - b.spiderSort);
    // console.log('Parallel Indicators', filteredIndicators);
    return filteredIndicators;
  }, [allIndicators]);


  useEffect(() => {
    if (resultsStatus === 'success') {
      if (resultsData) {
        if (resultsData.hasOwnProperty('baseRotation_yearly')) {
          const yearlyData = resultsData.baseRotation_yearly;
          if (yearlyData.length > 0) {
            setYearlyResults(yearlyData);
            if (crops) {
              const summaryData = SummariseDataByIndicator(
                yearlyData, parallelIndicators, crops, ['fallow']
              );
              // console.log('ResultsSectionB Summarised Data', summaryData);
              setParallelSummaryData(summaryData);
            }
          }
        }
      }
    }
  }, [dispatch, resultsData, resultsStatus, crops]);

  if (resultsStatus === 'loading') {
    return <LoadingComponent State='loading' />
  }

  if (resultsStatus === 'error') {
    return <LoadingComponent State='error' error={resultsError} />
  }

  let currentUnits = '';
  if (resultIndicatorObj) {
    currentUnits = resultIndicatorObj.units;
  }

  let currentResultIndicator = '';
  if (resultIndicatorObj) {
    currentResultIndicator = resultIndicatorObj.value;
  }

  let currentResultIndicatorDesc = '';
  if (resultIndicatorObj) {
    currentResultIndicatorDesc = resultIndicatorObj.display;
    if (resultIndicatorObj.details !== '') {
      currentResultIndicatorDesc += ` (${resultIndicatorObj.details})`;
    }
  }

  const handleResultIndicatorChange = (index, new_indicator) => {
    // In this instance the index can be ignored.
    console.log('SectionBResults', new_indicator);
    if (new_indicator) {
      if (new_indicator.length > 0) {
        dispatch(updateResultIndicator(new_indicator));
      }
    }
  };

  const handleSeasonalConditionsChange = (conditions) => {
    const display = [];

    if (conditions) {
      dispatch(updateSeasonalConditions(conditions));
      if (conditions.length > 0) {
        conditions.forEach(c => {
          const condObj = seasonalCond.find(s => s.value == c);
          if (condObj) {
            display.push(condObj.display);
          }
        });
      }
    }
    // console.log(conditions);
    return display;
  };

  return (
    <Box >
      <Box sx={{flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
        <Box sx={{flex: 1, display: "flex", flexDirection: "column", justifyContent: "flex-start"}}>
          <div style={{width: '100%'}}>
            <Typography
              sx={{ width: '90%', flexShrink: 0, ml: 10, pb: 1, color: 'black'}}
              fontSize={14} >
                The below figures provide separate results per harvested crop. Please use the 
                blue button to display results for indicators and seasonal climate conditions 
                of your interest.
            </Typography>
            <Box sx={{flex: 1, display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
              <div>
                <ResultIndicatorModal
                  CurrentIndicator={currentResultIndicator}
                  CurrentIndicatorDesc={currentResultIndicatorDesc}
                  SelectableIndicators={selectableIndicators}
                  OnResultIndicatorChange={handleResultIndicatorChange}
                  Label='Result Indicator:'
                  LabelBefore={false}
                  WithBorder={false} />
              </div>
              <div style={{marginLeft: '10px'}}>
                <SeasonalCondModal
                  OnSeasonalConditionsChange={handleSeasonalConditionsChange}
                  Label="Selected Seasons:"
                  SeasonalCondList={seasonalCond}
                  SelectedConditions={seasonalConditions} />
              </div>
            </Box>
          </div>              
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div id="boxplotcontainer" className={styles.graphPadded}>
              <InfoButton InfoKey="cl_box_plot" Position='auto' AnchorTo="boxplotcontainer">
                <BoxChart
                  ResultsData={yearlyResults}
                  ResultIndicator={currentResultIndicator}
                  ResultIndicatorDesc={currentResultIndicatorDesc}
                  SeasonalConditions={seasonalConditions}
                  Crops={crops}
                  Units={currentUnits}
                  InfoKey="box_plot"

                />
              </InfoButton>
            </div>
          </Grid>
          {resultsData && resultsData.Planted && resultsData.Missed && <Grid item xs={12}>
            <div id="plantingscontainer" className={styles.graphPadded}>
              <InfoButton InfoKey="cl_plantings" Position='auto' AnchorTo="plantingscontainer">
                <PlantingsTable Planted={resultsData.Planted} Missed={resultsData.Missed} />
              </InfoButton>
            </div>
          </Grid>}
          <Grid item xs={7}>
            <div id="linechartcontainer" className={styles.graphPadded}>
              <InfoButton InfoKey="cl_line_chart" Position='auto' AnchorTo="linechartcontainer">
                <LineChart
                  ResultsData={yearlyResults}
                  ResultIndicator={currentResultIndicator}
                  ResultIndicatorDesc={currentResultIndicatorDesc}
                  SeasonalConditions={seasonalConditions}
                  Crops={crops}
                  Units={currentUnits}
                />
              </InfoButton>
            </div>
          </Grid>
          <Grid item xs={5}>
            <div id="cdfchartcontainer" className={styles.graphPadded}>
              <InfoButton InfoKey="cl_cdf_chart" Position='auto' AnchorTo="cdfchartcontainer">
                <CDFChart
                  ResultsData={yearlyResults}
                  ResultIndicator={currentResultIndicator}
                  ResultIndicatorDesc={currentResultIndicatorDesc}
                  SeasonalConditions={seasonalConditions}
                  Crops={crops}
                  Units={currentUnits}
                />
              </InfoButton>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div id="parchartcontainer" className={styles.graphPadded}>
              <InfoButton InfoKey="cl_par_axis" Position='auto' AnchorTo="parchartcontainer">
                <ParallelChart
                  SummaryData={parallelSummaryData}
                  Crops={crops}
                  Explanation=''
                  InfoKey=''
                  TopScale={true}
                />
              </InfoButton>
            </div>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ResultsCropLevel;
