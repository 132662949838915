import React, {useState, useEffect} from 'react';
import PropTypes from "prop-types";
import pageStyles from './RAMainPage.module.scss';
import {useDispatch, useSelector} from "react-redux";
import ExploreStep from "./ExploreStep";
import CompareStep from "./CompareStep";
import InterpretationStep from "./InterpretationStep";
import Results from "./Results";
import {Button} from '@mui/material';
import styles from './RotationStepper.module.scss';
import {selectSite, selectPAWC, selectSiteDetail} from "../features/sitesSlice";
import {
  selectBaseRotationObj,
  selectNitrogenLevel,
  selectSowingRuleId,
  selectSeasonalConditions,
  selectSectionCIndicators,
  selectSectionCIndicatorObj,
  replaceRotation
} from "../features/rotationSlice";
import {
  fetchCropLevelResults,
  fetchRotationLevelResults,
  selectCropLevelStatus,
  selectRotationLevelStatus
} from "../features/resultsSlice";
import {
  selectSeasonalConditions as exploreSeasonalConditions,
  updateSeasonalConditions,
  fetchCompareData,
  selectAlternateRotation,
  selectCompareStatus,
  selectCompareError,
  resetAlternateRotation
} from "../features/exploreSlice";
import TextList from "../ra_components/TextList";

const StepsENUM = {
  "REFERENCE": 0,
  "EXPLORE": 1,
  "COMPARE": 2,
  "INTERPRETATION": 3,
};

const steps = ['Reference Results', 'Explore Alternatives', 'Compare Results', 'Interpretation'];
// const steps = ['Reference Results'];

const Analysis = (props) => {
  const dispatch = useDispatch();

  const [dataErrors, setDataErrors] = useState([]);

  // State for next/previous buttons.
  const [currentStep, setCurrentStep] = useState(0);
  const [showPrevious, setShowPrevious] = useState(false);
  const [showNext, setShowNext] = useState(true);
  const [previousLabel, setPreviousLabel] = useState('');
  const [nextLabel, setNextLabel] = useState('');

  const siteId = useSelector(selectSite);
  const siteDetails = useSelector(selectSiteDetail, { devModeChecks: { stabilityCheck: "never" }});
  const baseRotation = useSelector(selectBaseRotationObj);
  const pawc = useSelector(selectPAWC);
  const sowingRuleId = useSelector(selectSowingRuleId);
  const nitrogenLevel = useSelector(selectNitrogenLevel);
  const seasonalConds = useSelector(selectSeasonalConditions);
  const cIndicators = useSelector(selectSectionCIndicators);
  const cIndicatorObj = useSelector(selectSectionCIndicatorObj);
  const currentCropLevelStatus = useSelector(selectCropLevelStatus);
  const currentRotationLevelStatus = useSelector(selectRotationLevelStatus);
  const currentAltRotation = useSelector(selectAlternateRotation);

  const currentStepName = steps[currentStep];

  const load_cropLevel = () => {
    const fetchParms = {
      RotationId: baseRotation.id,
      SowingRuleId: sowingRuleId,
      NLevel: nitrogenLevel,
      siteId: siteId,
      pawc: pawc,
      Condition1: '',
      Condition2: '',
    };

    if (seasonalConds && seasonalConds.length > 0) {
      fetchParms.Condition1 = seasonalConds[0];
      if (seasonalConds.length > 1) {
        fetchParms.Condition2 = seasonalConds[1];
      }
    }

    // console.log('Analysis fetch parms', fetchParms);

    dispatch(fetchCropLevelResults({parms: fetchParms}));
  };

  const load_rotationLevel = () => {
    const fetchParms = {
      RotationId: baseRotation.id,
      Indicators: [...cIndicators],
      Indicator: cIndicatorObj.value,
      SowingRuleId: sowingRuleId,
      NLevel: nitrogenLevel,
      siteId: siteId,
      pawc: pawc,
      SeasonalCond: seasonalConds,
    };
    dispatch(fetchRotationLevelResults({parms: fetchParms}));
  };

  const load_exploreRotations = () => {
    // Nothing to do here. All done in the ExploreStep component now..
  };

  const load_compareRotations = () => {
    // Nothing to do here. All done in the CompareStep component now..
    // const fetchParms = {siteId, pawc};
    // fetchParms.baseRotationId = baseRotation.id;
    // fetchParms.altRotations = [...alternateRotations];
    // console.log('Explore Rotations: fetchParms', fetchParms);
    // dispatch(fetchCompareData({parms: fetchParms}));
  };

  useEffect(() => {
    if (currentStep === StepsENUM.REFERENCE) {
      if (currentCropLevelStatus === 'idle') {
        load_cropLevel();
      }
      if (currentRotationLevelStatus === 'idle') {
        load_rotationLevel();
      }
    }

    if (currentStep === StepsENUM.EXPLORE) {
      load_exploreRotations();
    }

    if (currentStep === StepsENUM.COMPARE) {
      load_compareRotations();
    }

    configureButtons();
    props.OnStepTitleChange(currentStepName);
  }, [dispatch, currentCropLevelStatus, currentRotationLevelStatus, currentStepName]);

  const nextDisabled = () => {
    let disabled = false;

    if (currentStep === StepsENUM.EXPLORE) {
      if (currentAltRotation === null) {
        disabled = true;
      } else {
        if (!currentAltRotation.hasOwnProperty('RotationId') || currentAltRotation.RotationId === null) {
          disabled = true;
        }
        if (!currentAltRotation.hasOwnProperty('SowingRuleId') || currentAltRotation.SowingRuleId === null) {
          disabled = true;
        }
        if (!currentAltRotation.hasOwnProperty('NLevel') || currentAltRotation.NLevel === null) {
          disabled = true;
        }
      }
    }
    return disabled;
  };

  const isLastStep = () => {
    const result = (currentStep === (steps.length - 1));
    return result;
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      const newStep = currentStep - 1;
      setCurrentStep(newStep);
    }
    configureButtons();

    props.OnStepTitleChange(currentStepName);
  };

  const handleNext = () => {
    if (!isLastStep()) {
      const newStep = currentStep + 1;
      setCurrentStep(newStep);
    }
    configureButtons();
    props.OnStepTitleChange(currentStepName);
  };

  const handleRegSwapAction = () => {
    // We are making the Alt Rotation the reference rotation and
    // the user is going to select a new alt rotation.
    dispatch(replaceRotation(currentAltRotation ));

    dispatch(resetAlternateRotation());

    setCurrentStep(StepsENUM.EXPLORE);
  };

  const handleNewAltAction = (newStep) => {
    // We are keeping the current reference rotation and
    // the user is going to select a new alt rotation.
    dispatch(resetAlternateRotation());

    setCurrentStep(StepsENUM.EXPLORE);
  };

  const configureButtons = () => {
    const previous = (currentStep > 0);
    const next = (currentStep < (steps.length - 1));

    let prevLabel = '';
    let nxtLabel = '';
    if (previous) {
      prevLabel = `< ${steps[currentStep - 1]}`;
    }
    if (next) {
      nxtLabel = `${steps[currentStep + 1]} >`;
    }

    setShowNext(next);
    setShowPrevious(previous);
    setNextLabel(nxtLabel);
    setPreviousLabel(prevLabel);
  };

  // const steps = ["Results", "Explore rotations", "Compare rotations"];
  const getStepContent = (step) => {
    switch(currentStep) {
      case StepsENUM.REFERENCE:
        return <div>
          <Results />
        </div>
      case StepsENUM.EXPLORE:
        return <div>
          <ExploreStep />
        </div>
      case StepsENUM.COMPARE:
        return <div>
          <CompareStep />
        </div>
      case StepsENUM.INTERPRETATION:
        return <div>
          <InterpretationStep
            RedirectNewAlt={handleNewAltAction}
            RedirectRefSwap={handleRegSwapAction}/>
        </div>
      default:
        // Should never happen.
        return <div> </div>
    }
  };

  return (
    // <Container maxWidth="lg">
    <div className={pageStyles.container}>
      <div style={{ width: '100%' }}>
        <div className={styles.middleContainer}>
          {getStepContent(currentStep)}
        </div>
        <div className={styles.lowerContainer}>
          <div>
            {dataErrors.length > 0 && <TextList Items={dataErrors} AsErrors={true} ListTitle="Errors (correct befor proceeding)" />}
          </div>
          <div className={styles.buttonContainer}>
            {previousLabel !== '' && <Button onClick={() => handlePrevious()}>{previousLabel}</Button>}
            {nextLabel !== '' && <Button
              disabled={nextDisabled()}
              onClick={(event) => handleNext(event)}>
              {nextLabel}
            </Button>}
          </div>
        </div>
      </div>
    </div>
  );
}

Analysis.propTypes = {
  OnStepTitleChange: PropTypes.func,
};

Analysis.defaultProps = {
  OnStepTitleChange: () => {},
};

export default Analysis;
