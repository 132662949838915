import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import miscHelper from "../ra_Helpers/miscHelper";
import {useDispatch, useSelector} from "react-redux";
import styles from "./RotationStepper.module.scss";
import {Typography} from "@mui/material";
import {
  updateSeasonalConditions,
  updateResultIndicator,
  selectResultIndicators,
  selectSeasonalConditions} from "../features/rotationSlice";
import CheckboxList from "../ra_components/CheckboxList";
import InfoButton from "../ra_components/InfoButton";

const seasonalConditionsList = miscHelper.seasonalClimateConditions();

const ResultStep = (props) => {
  const dispatch = useDispatch();

  const resultInd = useSelector(selectResultIndicators);
  const seasonalConditions = useSelector(selectSeasonalConditions);   // Users selection

  const selectableIndicators = resultInd.filter(r => r.selectable);

  const handleSeasonalConditionsSelect = (conditions) => {
    dispatch(updateSeasonalConditions(conditions));
  };

  const handleResultIndSelect = (res) => {
    if (res.length > 0) {
      dispatch(updateResultIndicator(res[0]));
    }
  };

  return (
    <div>
      <div className={styles.selectNitrogen}>
        <div className={styles.shadedTitle}>
          <Typography variant="h6" fontWeight="bold">
            A. Choose a results indicator to evaluate the performance of the reference crop rotation:
          </Typography>
        </div>
        <div className={styles.nitrogenContainer}>
          <Typography
            sx={{ width: '100%', flexShrink: 0, ml: 10, color: 'black'}}
            fontSize={16} >
            Note: You can shift between different results indicators at a later stage.
          </Typography>
          <CheckboxList
            Options={resultInd}
            Columns={1}
            Title=""
            TitleBackground="#dfdfdf"
            Selected={[props.ResultIndicator]}
            OnSelect={handleResultIndSelect} />
        </div>
      </div>
      <div className={styles.selectNitrogen}>
        <div className={styles.shadedTitle}>
          <Typography variant="h6" fontWeight="bold">
            B. Select a maximum of two seasonal climate conditions:
          </Typography>
        </div>
        <div className={styles.nitrogenContainer}>
          <div style={{display: 'inline-block', marginLeft: '45px'}}>
            <InfoButton InfoKey="wb_desc" Width="50%">
              <Typography variant="h6" fontWeight="bold">
                Further detail about the water balance ratio.
              </Typography>
            </InfoButton>
          </div>

          <CheckboxList
            Options={seasonalConditionsList}
            Columns={1}
            Title=""
            MaxSelectCount={2}
            TitleBackground="#dfdfdf"
            Selected={seasonalConditions}
            OnSelect={handleSeasonalConditionsSelect} />
        </div>
      </div>
    </div>
  );
}

ResultStep.propTypes = {
  ResultIndicator: PropTypes.string.isRequired,
  SeasonalConditions: PropTypes.array.isRequired,
}

export default ResultStep;
