import React, {useState, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import { useLocation } from "react-router-dom";
import PropTypes from 'prop-types';
import styles from "./RotationStepper.module.scss";
import {useWhatChanged, setUseWhatChange} from "@simbathesailor/use-what-changed";
import {Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import pageStyles from "./Results.module.scss";
import CompareRotationList from "../ra_components/CompareRotationList";
import CompareRotationLevel from "./CompareRotationLevel";
import CompareCropLevel from "./CompareCropLevel";
import {
  fetchCompareData,
  selectCompareStatus,
  selectCompareError,
  selectSeasonalConditions,
  selectAlternateRotation,
  selectCompareData,
  selectExploreRotationLevelIndicator,
  selectExploreCropLevelIndicator,
  updateCropLevelIndicator, 
  updateSeasonalConditions, updateRotationLevelIndicator,
} from "../features/exploreSlice";
import {
  selectCrops,
  getReferenceRotation,
  selectSeasonalConditions as previousSeasonalConditions
} from '../features/rotationSlice';
import {selectSite, selectPAWC} from "../features/sitesSlice";
import LoadingComponent from "../ra_components/LoadingComponent";
import miscHelper from "../ra_Helpers/miscHelper";

const seasonalCond = miscHelper.seasonalClimateConditions();

//#region Defined styles Accordion components
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={4} square={false} {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  borderRadius: '10px',
  marginTop: '2px',
  backgroundColor: 'lightgray',
  color: 'black',
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '1.4rem', color: '#fefefe'}} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  // flexDirection: 'row-reverse',   Commenting this out puts icons on the right
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  fontWeight: 'bold',
  color: '#fefefe',
  marginLeft: '10px',
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, .7)',
  padding: theme.spacing(0),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
//#endregion

const CompareStep = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [expandedAry, setExpandedAry] = useState(['sectionA', 'sectionB', 'sectionC']);
  const siteId = useSelector(selectSite);
  const pawc = useSelector(selectPAWC);
  const previousConditions = useSelector(previousSeasonalConditions);

  const crops = useSelector(selectCrops);
  const compareData = useSelector(selectCompareData);
  const compareStatus = useSelector(selectCompareStatus);
  const compareError = useSelector(selectCompareError);

  // const referenceRotation = useSelector(selectReferenceRotation);
  const referenceRotation = useSelector(getReferenceRotation);
  const alternateRotation = useSelector(selectAlternateRotation);
  const seasonalConditions = useSelector(selectSeasonalConditions);
  const currentRotationLevelIndicator = useSelector(selectExploreRotationLevelIndicator);
  const currentCropLevelIndicator = useSelector(selectExploreCropLevelIndicator);

  // console.log('Comparing data', compareData);

  useEffect(() => {
    if (!seasonalConditions || seasonalConditions.length === 0) {
      // console.log('Updating seasonal conditions');
      dispatch(updateSeasonalConditions([...previousConditions]));
    }
  }, []);

  useEffect(() => {
    console.log('useEffect CompareStep ref/alt:', referenceRotation, alternateRotation);
    if (referenceRotation && alternateRotation) {
      // console.log('Ref Rotation', referenceRotation);
      // console.log('Alt Rotation', alternateRotation);
      const fetchParms = {
        Rotations: [referenceRotation, alternateRotation],
        // SeasonalCond: seasonalConditions,
        siteId: siteId,
        pawc: pawc,
      };
      // console.log('Fetch Parms', fetchParms);
      dispatch(fetchCompareData({parms: fetchParms}));
    }
  }, [dispatch, referenceRotation, alternateRotation, pawc, siteId]);

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_path: location.pathname + location.search + location.hash,
      page_search: location.search,
      page_hash: location.hash
    });
  }, [location]);

  const inExpanded = (panelName) => {
    if (expandedAry.indexOf(panelName) !== -1) {
      return true;
    } else {
      return false;
    }
  };

  const toggleExpanded = (panelName) => {
    let newExpanded;
    if (expandedAry.indexOf(panelName) !== -1) {
      // In the array so remove it.
      newExpanded = expandedAry.filter(x => x !== panelName);
    } else {
      newExpanded = [...expandedAry, panelName];
    }

    setExpandedAry(newExpanded);
  };

  const handleRotationLevelIndicatorChange = (newIndicator) => {
    if (newIndicator) {
      const newInd = newIndicator.toString();
      dispatch(updateRotationLevelIndicator({resultIndicator: newInd}));
    }
  };

  const handleCropLevelIndicatorChange = (newIndicator) => {
    if (newIndicator) {
      const newInd = newIndicator.toString();
      dispatch(updateCropLevelIndicator({resultIndicator: newInd}));
    }
  };

  const handleCurrentConditionsChange = (newConditions) => {
    const display = [];
    const conditionSet = new Set();

    // console.log('CompareStep handleCurrentConditionsChange', newConditions);
    if (Array.isArray(newConditions)) {
      if (newConditions.length > 0) {

        newConditions.forEach(c => {
          const conditionObj = seasonalCond.find(s => s.value === c);
          if (conditionObj) {
            conditionSet.add(c);
            display.push(conditionObj.display);
          }
        });

        // Receive an array and pass the array.
        const updatedConditions = Array.from(conditionSet);
        // console.log('CompareStep handleCurrentConditionsChange', updatedConditions);

        dispatch(updateSeasonalConditions(updatedConditions));
      }
    }
  };

  if (compareStatus === "loading") {
    return <LoadingComponent State="loading" />;
  }

  if (compareStatus === "failed") {
    return <LoadingComponent State="error" Error={compareError} />;
  }

  // Not sure why this is needed as according to console these
  // values are already numbers. PropTypes has other ideas!!!
  const numReferenceId = Number(referenceRotation.RotationId);
  const numAlternateId = Number(alternateRotation.RotationId);

  // console.log(compareData);

  return (
    <div style={{padding: 2}}>
      <Accordion
        expanded={inExpanded('sectionA')}
        onChange={() => toggleExpanded('sectionA')}>
        <AccordionSummary aria-controls="sectionA-content" id="sectionA-header">
          <Typography
            sx={{ width: '33%', flexShrink: 0, ml: 4, color: 'black'}}
            fontWeight="bold"
            fontSize={20}>
            A. Selected cropping systems
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{maxHeight: '200px'}} >
          <Typography
            sx={{ width: '90%', flexShrink: 0, ml: 10, pb: 1, color: 'black'}}
            fontSize={14} >
              The below table summarises the crop rotations and management practices 
              that you selected for analysis.
          </Typography>
          <div className={pageStyles.tableContainer}>
            {compareData && <CompareRotationList
              Rotations={compareData.rotations}
              Crops={crops}
              Selected={[]} />
            }
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={inExpanded('sectionB')}
        onChange={() => toggleExpanded('sectionB')}>
        <AccordionSummary aria-controls="sectionB-content" id="sectionB-header">
          <Typography
            sx={{ width: '33%', flexShrink: 0, ml: 4, color: 'black'}}
            fontWeight="bold"
            fontSize={20}>
            B. Rotation-level results
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {compareData && <CompareRotationLevel
            CompareData={compareData}
            CurrentIndicator={currentRotationLevelIndicator}
            CurrentConditions={seasonalConditions}
            OnResultIndicatorChange={handleRotationLevelIndicatorChange}
            OnSelectedConditionsChange={handleCurrentConditionsChange}/>
          }
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={inExpanded('sectionC')}
        onChange={() => toggleExpanded('sectionC')}>
        <AccordionSummary aria-controls="sectionC-content" id="sectionC-header">
          <Typography
            sx={{ width: '33%', flexShrink: 0, ml: 4, color: 'black'}}
            fontWeight="bold"
            fontSize={20}>
            C. Crop-level results
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {compareData && <CompareCropLevel
            CompareData={compareData}
            RefRotationId={numReferenceId}
            AltRotationId={numAlternateId}
            OnIndicatorChange={handleCropLevelIndicatorChange}
            OnConditionsChange={handleCurrentConditionsChange}
            CurrentIndicator={currentCropLevelIndicator}
            CurrentConditions={seasonalConditions}/>
          }
        </AccordionDetails>
      </Accordion>

    </div>
  );
}

export default CompareStep;
