import React, {useState, useEffect, useRef, useMemo} from 'react';
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Typography from "@mui/material/Typography";
import {convertSowingRulesToList} from "../functions";
import {styled} from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import {
  selectRotations,
  selectCrops,
  getReferenceRotation,
} from "../features/rotationSlice";
import {
  selectSelectedCrops,
  updateSelectedCrops,
  clearSelectedCrops,
  selectAlternateRotation,
  updateAlternateRotation
} from "../features/exploreSlice";
import {selectSowingRules} from "../features/sowingRulesSlice";
import pageStyles from './ExploreStep.module.scss';
import ExploreQuestions from "../ra_components/ExploreQuestions";
import CropList from "../ra_components/CropList";
import styles from "./RotationStepper.module.scss";
import RotationList from "../ra_components/RotationList";
import CheckboxList from "../ra_components/CheckboxList";
import miscHelper from "../ra_Helpers/miscHelper";

//#region Defined styles Accordion components
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={4} square={false} {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  borderRadius: '10px',
  marginTop: '2px',
  backgroundColor: 'lightgray',
  color: 'black',
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '1.4rem', color: '#fefefe'}} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  // flexDirection: 'row-reverse',   Commenting this out puts icons on the right
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  fontWeight: 'bold',
  color: '#fefefe',
  marginLeft: '10px',
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, .7)',
  padding: theme.spacing(0),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
//#endregion

const nitrogenApplied = miscHelper.nitrogenApplied();

const ExploreStep = (props) => {
  const effectDone = useRef(false);
  const location = useLocation();
  const dispatch = useDispatch();

  const [expandedAry, setExpandedAry] = useState(['exploreA', 'exploreB', 'exploreC', 'exploreD']);
  const [cropFilter, setCropFilter] = useState('');

  const crops = useSelector(selectCrops);
  const allRotations = useSelector(selectRotations);
  const selectedCrops = useSelector(selectSelectedCrops);
  const sowingRules = useSelector(selectSowingRules);

  const alternateRotation = useSelector(selectAlternateRotation);
  // const referenceRotation = useSelector(selectReferenceRotation);
  const referenceRotation = useSelector(getReferenceRotation);

  useEffect(() => {
    if (effectDone.current === true) {
      window.scrollTo({top: 0, left:0, behavior: 'smooth'});
    }

    return () => {
      effectDone.current = true;
    }
  }, [dispatch]);

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_path: location.pathname + location.search + location.hash,
      page_search: location.search,
      page_hash: location.hash
    });
    window.scrollTo({top: 0, left:0, behavior: 'smooth'});
  }, [location]);

  const sowingRulesList = useMemo(() => {
    const asAList = convertSowingRulesToList(sowingRules);
    return asAList;
  }, [sowingRules]);

  const allowableRotations = useMemo(() => {
    if (referenceRotation) {
      // const allowed = allRotations.filter(r => r.id !== referenceRotation.RotationId);
      const allowed = allRotations.slice(0);
      return allowed;
    } else {
      return allRotations.slice();
    }
  }, [allRotations, referenceRotation]);

  //#region Update alternate rotation with user selections
  const handleRotationSelect = (selectedRotations) => {
    if (Array.isArray(selectedRotations)) {
      if (selectedRotations.length > 0) {
        const altRotation = selectedRotations[0];
        if (altRotation) {
          const newRotation = {...alternateRotation, RotationId: altRotation};
          dispatch(updateAlternateRotation({rotation: newRotation}));
        }
      }
    }
  };

  const handleNitroSelect = (nApplied) => {
    if (Array.isArray(nApplied)) {
      if (nApplied.length > 0) {
        const appliedN = nApplied[0].toString();
        const newRotation = {...alternateRotation, NLevel: appliedN};
        dispatch(updateAlternateRotation({rotation: newRotation}));
      }
    }
  };

  const handleSowingRuleSelect = (nSowing) => {
    if (Array.isArray(nSowing)) {
      if (nSowing.length > 0) {
        const ruleId = Number(nSowing[0]);
        if (ruleId !== 0) {
          const newRotation = {...alternateRotation, SowingRuleId: ruleId};
          // console.log(newRotation);
          dispatch(updateAlternateRotation({rotation: newRotation}));
        }
      }
    }
  };

  const handleCropSelect = (selected) => {
    // Always passed an array of crop names
    if (Array.isArray(selected)) {
      if (selected.length > 0) {
        const userCrops = selected.join(',');
        setCropFilter(userCrops);
        dispatch(updateSelectedCrops(selected));
      } else {
        setCropFilter('');
        dispatch(clearSelectedCrops());
      }
    }
  };
  //#endregion

  const inExpanded = (panelName) => {
    if (expandedAry.indexOf(panelName) !== -1) {
      return true;
    } else {
      return false;
    }
  };

  const toggleExpanded = (panelName) => {
    let newExpanded;
    if (expandedAry.indexOf(panelName) !== -1) {
      // In the array so remove it.
      newExpanded = expandedAry.filter(x => x !== panelName);
    } else {
      newExpanded = [...expandedAry, panelName];
    }

    setExpandedAry(newExpanded);
  };

  let alternateRotationAry = [];
  if (alternateRotation) {
    if (alternateRotation.RotationId) {
      alternateRotationAry.push(alternateRotation.RotationId);
    }
  }

  let alternateSowingRuleAry = [];
  if (alternateRotation) {
    if (alternateRotation.SowingRuleId) {
      // console.log('alt sowing rule id', alternateRotation.SowingRuleId);
      alternateSowingRuleAry.push(alternateRotation.SowingRuleId.toString());
    }
  }

  let alternateNitrogenAry = [];
  if (alternateRotation) {
    if (alternateRotation.NLevel) {
      alternateNitrogenAry.push(alternateRotation.NLevel);
    }
  }

  return (
    <div style={{padding: 2}}>
      <Accordion
        expanded={inExpanded('exploreA')}
        onChange={() => toggleExpanded('exploreA')}>
        <AccordionSummary aria-controls="exploreA-content" id="exploreA-header">
          <Typography
            sx={{ width: '33%', flexShrink: 0, ml: 4, color: 'black'}}
            fontWeight="bold"
            fontSize={20} >
            A. Explore an alternative cropping strategy
          </Typography>
        </AccordionSummary>
        <AccordionDetails >
          <ExploreQuestions />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={inExpanded('exploreB')}
        onChange={() => toggleExpanded('exploreB')}>
        <AccordionSummary aria-controls="exploreB-content" id="exploreB-header">
          <Typography
            sx={{ width: '33%', flexShrink: 0, ml: 4, color: 'black'}}
            fontWeight="bold"
            fontSize={20} >
            B. Select an alternative crop rotation
          </Typography>
        </AccordionSummary>
        <AccordionDetails >
          <Typography className={pageStyles.stepHeading} sx={{marginTop: "10px"}} >
            Step 1: Select the crops included in the alternative crop rotation
          </Typography>
          <div >
            <CropList
              Crops={crops}
              Columns={4}
              Title=""
              CropSelect={handleCropSelect}
              SelectedCrops={selectedCrops}
            />
          </div>

          <Typography className={pageStyles.stepHeading} sx={{marginTop: "10px"}} >
            Step 2: Select one alternative crop rotation
          </Typography>
          <div className={styles.rotationlistContainer}>
            <RotationList
              Rotations={allowableRotations}
              Crops={crops}
              Title=""
              OnSelect={handleRotationSelect}
              MaxSelectCount={1}
              CropFilter={cropFilter}
              Selected={alternateRotationAry}
            />
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={inExpanded('exploreC')}
        onChange={() => toggleExpanded('exploreC')}>
        <AccordionSummary aria-controls="exploreC-content" id="exploreC-header">
          <Typography
            sx={{ width: '33%', flexShrink: 0, ml: 4, color: 'black'}}
            fontWeight="bold"
            fontSize={20} >
            C. Select a rule when crops are sown within the planting window.
          </Typography>
        </AccordionSummary>
        <AccordionDetails >
          <Typography className={pageStyles.stepHeading} sx={{marginTop: "10px"}} >
            Sowing rule for the alternative crop rotation.
          </Typography>
          <div className={styles.selectNitrogen}>
              <CheckboxList
                Options={sowingRulesList}
                Columns={1}
                Title=""
                TitleBackground="#dfdfdf"
                OnSelect={handleSowingRuleSelect}
                Selected={alternateSowingRuleAry}
              />
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={inExpanded('exploreD')}
        onChange={() => toggleExpanded('exploreD')}>
        <AccordionSummary aria-controls="exploreD-content" id="exploreD-header">
          <Typography
            sx={{ width: '33%', flexShrink: 0, ml: 4, color: 'black'}}
            fontWeight="bold"
            fontSize={20} >
            D. Select the quantity of nitrogen applied to each crop:
          </Typography>
        </AccordionSummary>
        <AccordionDetails >
          <Typography className={pageStyles.stepHeading} sx={{marginTop: "10px"}} >
            Nitrogen level for the alternative crop rotation.
          </Typography>
            <div className={styles.nitrogenContainer}>
              <CheckboxList
                Options={nitrogenApplied}
                Columns={1}
                Title=""
                TitleBackground="#dfdfdf"
                OnSelect={handleNitroSelect}
                Selected={alternateNitrogenAry}
              />
            </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default ExploreStep;
